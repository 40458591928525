<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :offset="300">
    <van-list
      v-model="loading"
      class="list"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(item, index) in list" :key="index" class="item" @click="onOpen(item)">
        <img :src="item.img" class="img" alt="">
      </div>
    </van-list>
  </van-pull-refresh>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          video: 'http://sws.peppa.zjsws.net/%E5%85%89%E7%BA%A4%E6%94%B6%E5%8F%91%E5%99%A8%E7%9A%84%E6%95%85%E9%9A%9C%E6%A3%80%E6%B5%8B.mp4',
          img: require('../../assets/images/4.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E4%B8%BB%E7%94%B5%E6%BA%90%E7%BA%BF.mp4',
          img: require('../../assets/images/6.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E4%B8%BB%E5%85%89%E7%BA%A4%E6%8E%92%E6%9F%A5.mp4',
          img: require('../../assets/images/5.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E4%B8%BB%E7%A9%BA%E5%BC%80.mp4',
          img: require('../../assets/images/7.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E5%85%89%E7%BA%A4%E6%94%B6%E5%8F%91%E5%99%A8.mp4',
          img: require('../../assets/images/8.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E6%91%84%E5%83%8F%E6%9C%BA%E7%94%B5%E6%BA%90.mp4',
          img: require('../../assets/images/9.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E6%B5%AA%E6%B6%8C%E4%BF%9D%E6%8A%A4%E5%99%A8.mp4',
          img: require('../../assets/images/10.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E8%BF%9B%E7%94%B5%E5%92%8C%E5%87%BA%E7%94%B5.mp4',
          img: require('../../assets/images/11.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E9%98%B2%E9%9B%B7%E5%99%A8.mp4',
          img: require('../../assets/images/12.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E7%94%B5%E8%84%91%E7%AB%AF%E6%8E%92%E6%9F%A5.mp4',
          img: require('../../assets/images/13.png'),
        },

        {
          video: 'http://sws.peppa.zjsws.net/%E9%A3%9E%E4%B9%A620230508-140600.mp4',
          img: require('../../assets/images/3.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E9%A3%9E%E4%B9%A620230508-140552.mp4',
          img: require('../../assets/images/1.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/lr7lpnvAETxQ4OjQemMJClXjwbfB',
          img: require('../../assets/images/2.png'),
        },
        {
          video: 'http://sws.peppa.zjsws.net/%E7%AE%B1%E5%86%85%E8%AE%BE%E5%A4%87%E4%BB%8B%E7%BB%8D%EF%BC%88%E6%8B%86%E8%A7%A3%E7%89%88%EF%BC%89.mp4',
          img: require('../../assets/images/14.png'),
        },
      ],
      loading: false,
      finished: false,
      refreshing: false,
      baseList: []
    };
  },
  created() {
    this.baseList = JSON.parse(JSON.stringify(this.list))
  },
  methods: {
    onOpen(item) {
      window.open(item.video);
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = this.baseList;
          this.refreshing = false;
        }
        this.loading = true
        if (this.list.length >= 14) {
          this.finished = true
        }
        this.loading = false;
      }, 1000)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  padding: 24px 12px;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
}
.item {
  float: left;
  border-radius: 8px;
  width: 166px;
  margin-bottom: 12px;
  border: 2px solid #ebebeb;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.15);
  border-radius: 8px;
  &:nth-child(2n+1) {
    margin-right: 11px;
  }
  .img {
    width: 100%;
    height: 124px;
    border-radius: 8px;
    display: block;
  }
}
</style>